<template>
<div>
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

  <div class="content">
    <validation-observer ref="VForm">
      <b-form @submit.prevent="doSubmit()">
        <div class="card">
          <div class="card-header bg-white">
            <div class="row">
              <div class="col-md-4">
                <h6 class="card-title font-weight-semibold">Form Resume Medis</h6>
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="" for="">Tanggal Masuk</label>
                      <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                        </div>
                        <datepicker input-class="form-control transparent" placeholder="Tanggal Masuk" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.entry_date" @change="updateRow(row)">
                        </datepicker>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="" for="">Tanggal Keluar</label>
                      <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                        </div>
                        <datepicker input-class="form-control transparent" placeholder="Tanggal Keluar" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.exit_date" @change="updateRow(row)">
                        </datepicker>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="" for="">Alasan Masuk RS</label>
                      <b-form-textarea name="AlasanMasukRS" id="AlasanMasukRS" rows="4" class="form-control" placeholder="Alasan Masuk RS" v-model="row.hospitalization_reason" @change="updateRow(row)"></b-form-textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="head_panel_red">
                      <div class="d-flex justify-content-between align-items-center">
                        <h3>ALERGI </h3>
                        <b-form-radio-group v-model="row.has_alergi" :options="Config.mr.yesNoOptV5" class="mb-0" @change="updateRow(row)" />
                      </div>
                    </div>
                    <table class="table table-sm table-bordered" v-if="row.has_alergi == 'Y'">
                      <thead>
                        <tr>
                          <th width="33%">Jenis</th>
                          <th>Informasi Alergi</th>
                          <th width="64"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, k) in row.alergi" :key="`k-alergi-${k}`">
                          <td>
                            <v-select :options="mrJenisAlergi" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 104px;" v-model="v.jenis" @input="updateRow(row)" />
                          </td>
                          <td>
                            <b-form-input type="text" placeholder="cth. amoxicilin" v-model="v.name" @change="updateRow(row)" />
                          </td>
                          <td>
                            <a href="javascript:;" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger" @click="removeData('alergi', k)" v-if="row.alergi.length > 1">
                              <i class="icon-trash"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="3" class="text-center">
                            <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addData('alergi')"><i class="icon-plus2 mr-1"></i>Tambah</a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                  </div>
                  <div class="card-body p-3 pb-4">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Tekanan Darah</label>
                          <div class="input-group">
                            <b-form-input type="tel" name="name" class="form-control" placeholder="Sistolik" v-model="row.sistolik" :formatter="Formatter.number" @change="updateRow(row)" />
                            <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                            <b-form-input type="tel" class="form-control" placeholder="Diastolik" v-model="row.diastolik" :formatter="Formatter.number" @change="updateRow(row)" />
                            <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Nadi<small class="txt_mandatory">*</small></label>
                          <div class="input-group">
                            <b-form-input type="text" class="form-control" v-model="row.nadi" :formatter="Formatter.number" @change="updateRow(row)" />

                            <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                            <div class="input-group-append">
                              <v-select :options="MonitoringOptions.mrNadi" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 104px;" v-model="row.nadi_type" />
                            </div>
                          </div>

                          <VValidate name="Nadi" :value="row.nadi && row.nadi_type" :rules="mrValidation.nadi" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Pernafasan<small class="txt_mandatory">*</small></label>
                          <div class="input-group">
                            <b-form-input type="text" class="form-control" v-model="row.pernafasan" :formatter="Formatter.number" @change="updateRow(row)" />
                            <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                          </div>

                          <VValidate name="Pernafasan" v-model="row.pernafasan" :rules="mrValidation.pernafasan" />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>SPO2<small class="txt_mandatory">*</small></label>
                          <div class="input-group">
                            <b-form-input type="text" class="form-control" v-model="row.sp_o2" :formatter="Formatter.decimalNumber" @change="updateRow(row)" />
                            <div class="input-group-append"><span class="input-group-text">%</span>
                            </div>
                          </div>

                          <VValidate name="SPO2" v-model="row.sp_o2" :rules="mrValidation.sp_o2" />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Suhu<small class="txt_mandatory">*</small></label>
                          <div class="input-group">
                            <b-form-input type="text" class="form-control" v-model="row.suhu" :formatter="Formatter.decimalNumber" @change="updateRow(row)" />
                            <div class="input-group-append"><span class="input-group-text">&deg;C</span>
                            </div>
                          </div>

                          <VValidate name="Suhu" v-model="row.suhu" :rules="mrValidation.suhu" />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>GDA</label>
                          <div class="input-group">
                            <b-form-input type="text" class="form-control" v-model="row.gda" :formatter="Formatter.decimalNumber" @change="updateRow(row)" />
                            <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="card mb-2">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="alert alert-info p-3">Silakan pilih/ceklis bagian yang memiliki kelainan</div>
                    <div class="bg-light card-header c-pointer collapsed">
                      <div class="d-flex align-items-center justify-content-between" v-b-toggle.wrap-keadaan-umum>
                        <span class="card-title font-weight-semibold">Klik untuk {{ showKeadaanUmum ? 'Menyembunyikan' : 'Menampilkan' }} Bagian Keadaan Umum</span>
                        <i :class="{'icon-arrow-down22': !showKeadaanUmum, 'icon-arrow-up22': showKeadaanUmum}" style="font-size: 20px;"></i>
                      </div>
                    </div>

                    <b-collapse id="wrap-keadaan-umum" v-model="showKeadaanUmum">
                      <div class="row g-0" id="GenPartRow">
                        <div class="col-md-6">
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.kepala>
                              <div class="font-weight-semibold">Kepala</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="kepala" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_kepala" />
                                <div class="gen-input mt-2" v-if="row.ku_kepala == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian kepala sebelah kiri" rows="2" v-model="row.ku_kepala_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" data-toggle="collapse" data-target="#collapseHidung" aria-expanded="true" v-b-toggle.hidung>
                              <div class="font-weight-semibold">Hidung</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="hidung" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_hidung" />
                                <div class="gen-input mt-2" v-if="row.ku_hidung == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian hidung sebelah kiri" rows="2" v-model="row.ku_hidung_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.bibir>
                              <div class="font-weight-semibold">Bibir</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="bibir" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_bibir" />
                                <div class="gen-input mt-2" v-if="row.ku_bibir == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian bibir sebelah kiri" rows="2" v-model="row.ku_bibir_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.lidah>
                              <div class="font-weight-semibold">Lidah</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="lidah" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_lidah" />
                                <div class="gen-input mt-2" v-if="row.ku_lidah == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian lidah sebelah kiri" rows="2" v-model="row.ku_lidah_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.leher>
                              <div class="font-weight-semibold">Leher</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="leher" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_leher" />
                                <div class="gen-input mt-2" v-if="row.ku_leher == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian leher sebelah kiri" rows="2" v-model="row.ku_leher_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.tonsil>
                              <div class="font-weight-semibold">Tonsil</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="tonsil" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_tonsil" />
                                <div class="gen-input mt-2" v-if="row.ku_tonsil == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian tonsil sebelah kiri" rows="2" v-model="row.ku_tonsil_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.payudara>
                              <div class="font-weight-semibold">Payudara</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="payudara" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_payudara" />
                                <div class="gen-input mt-2" v-if="row.ku_payudara == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian payudara sebelah kiri" rows="2" v-model="row.ku_payudara_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.perut>
                              <div class="font-weight-semibold">Perut</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="perut" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_perut" />
                                <div class="gen-input mt-2" v-if="row.ku_perut == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian perut sebelah kiri" rows="2" v-model="row.ku_perut_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.anus>
                              <div class="font-weight-semibold">Anus/Dubur</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="anus" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_anus" />
                                <div class="gen-input mt-2" v-if="row.ku_anus == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian anus sebelah kiri" rows="2" v-model="row.ku_anus_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.lengan-bawah>
                              <div class="font-weight-semibold">Lengan Bawah</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="lengan-bawah" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_lengan_bawah" />
                                <div class="gen-input mt-2" v-if="row.ku_lengan_bawah == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian lengan_bawah sebelah kiri" rows="2" v-model="row.ku_lengan_bawah_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.kuku-tangan>
                              <div class="font-weight-semibold">Kuku Tangan</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="kuku-tangan" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_kuku_tangan" />
                                <div class="gen-input mt-2" v-if="row.ku_kuku_tangan == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian kuku_tangan sebelah kiri" rows="2" v-model="row.ku_kuku_tangan_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.tungkai-atas>
                              <div class="font-weight-semibold">Tungkai Atas</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="tungkai-atas" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_tungkai_atas" />
                                <div class="gen-input mt-2" v-if="row.ku_tungkai_atas == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian tungaki_atas sebelah kiri" rows="2" v-model="row.ku_tungkai_atas_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.jari-kaki>
                              <div class="font-weight-semibold">Jari Kaki</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="jari-kaki" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_jari_kaki" />
                                <div class="gen-input mt-2" v-if="row.ku_jari_kaki == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian jari_kaki sebelah kiri" rows="2" v-model="row.ku_jari_kaki_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.persendian-kaki>
                              <div class="font-weight-semibold">Persendian Kaki</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="persendian-kaki" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_persendian_kaki" />
                                <div class="gen-input mt-2" v-if="row.ku_persendian_kaki == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian persendian_kaki sebelah kiri" rows="2" v-model="row.ku_persendian_kaki_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.mata>
                              <div class="font-weight-semibold">Mata</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="mata" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_mata" />
                                <div class="gen-input mt-2" v-if="row.ku_mata == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian mata sebelah kiri" rows="2" v-model="row.ku_mata_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.rambut>
                              <div class="font-weight-semibold">Rambut</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="rambut" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_rambut" />
                                <div class="gen-input mt-2" v-if="row.ku_rambut == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian rambut sebelah kiri" rows="2" v-model="row.ku_rambut_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.gigi-geligi>
                              <div class="font-weight-semibold">Gigi Geligi</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="gigi-geligi" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_gigi_geligi" />
                                <div class="gen-input mt-2" v-if="row.ku_gigi_geligi == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian gigi_geligi sebelah kiri" rows="2" v-model="row.ku_gigi_geligi_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.langit-langit>
                              <div class="font-weight-semibold">Langit-langit</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="langit-langit" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_langit_langit" />
                                <div class="gen-input mt-2" v-if="row.ku_langit_langit == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian langit_langit sebelah kiri" rows="2" v-model="row.ku_langit_langit_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.tenggorokan>
                              <div class="font-weight-semibold">Tenggorokan</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="tenggorokan" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_tenggorokan" />
                                <div class="gen-input mt-2" v-if="row.ku_tenggorokan == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian tenggorokan sebelah kiri" rows="2" v-model="row.ku_tenggorokan_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.dada>
                              <div class="font-weight-semibold">Dada</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="dada" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_dada" />
                                <div class="gen-input mt-2" v-if="row.ku_dada == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian dada sebelah kiri" rows="2" v-model="row.ku_dada_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.punggung>
                              <div class="font-weight-semibold">Punggung</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="punggung" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_punggung" />
                                <div class="gen-input mt-2" v-if="row.ku_punggung == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian punggung sebelah kiri" rows="2" v-model="row.ku_punggung_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.genital>
                              <div class="font-weight-semibold">Genital</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="genital" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_genital" />
                                <div class="gen-input mt-2" v-if="row.ku_genital == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian genital sebelah kiri" rows="2" v-model="row.ku_genital_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.lengan-atas>
                              <div class="font-weight-semibold">Lengan Atas</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="lengan-atas" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_lengan_atas" />
                                <div class="gen-input mt-2" v-if="row.ku_lengan_atas == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian lengan_atas sebelah kiri" rows="2" v-model="row.ku_lengan_atas_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.jari-tangan>
                              <div class="font-weight-semibold">Jari Tangan</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="jari-tangan" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_jari_tangan" />
                                <div class="gen-input mt-2" v-if="row.ku_jari_tangan == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian jari_tangan sebelah kiri" rows="2" v-model="row.ku_jari_tangan_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.persendian-tangan>
                              <div class="font-weight-semibold">Persendian Tangan</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="persendian-tangan" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_persendian_tangan" />
                                <div class="gen-input mt-2" v-if="row.ku_persendian_tangan == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian persendian_tangan sebelah kiri" rows="2" v-model="row.ku_persendian_tangan_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.tungkai-bawah>
                              <div class="font-weight-semibold">Tungkai Bawah</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="tungkai-bawah" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_tungkai_bawah" />
                                <div class="gen-input mt-2" v-if="row.ku_tungkai_bawah == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian tungkai_bawah sebelah kiri" rows="2" v-model="row.ku_tungkai_bawah_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.kuku-kaki>
                              <div class="font-weight-semibold">Kuku Kaki</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="kuku-kaki" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_kuku_kaki" />
                                <div class="gen-input mt-2" v-if="row.ku_kuku_kaki == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian kuku_kaki sebelah kiri" rows="2" v-model="row.ku_kuku_kaki_text" @change="updateRow(row)"></b-form-textarea>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                          <div class="gen-part">
                            <div class="d-flex align-items-center justify-content-between gen-part-head" v-b-toggle.saraf>
                              <div class="font-weight-semibold">Saraf</div>
                              <i class="icon-arrow-down22"></i>
                            </div>
                            <b-collapse id="saraf" class="mt-2">
                              <div class="gen-part-body">
                                <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP" @change="updateRow(row)" v-model="row.ku_saraf" />
                                <div class="gen-input mt-2" v-if="row.ku_saraf == 'TN'">
                                  <b-form-textarea placeholder="cth. pembengkakan pada bagian saraf sebelah kiri" rows="2" v-model="row.ku_saraf_text" @change="updateRow(row)"></b-form-textarea>
                                  <div class="gen-input-saraf mt-2" data-select2-id="162">
                                    <div class="mb-2">
                                      <label for="refleksFisiologis">Refleks Fisiologis</label>
                                      <b-textarea v-model="row.ku_saraf_r_fisiologis" class="form-control" rows="2" @change="updateRow(row)"></b-textarea>
                                    </div>
                                    <div class="mb-2">
                                      <label for="refleksPatologis">Refleks Patologis</label>
                                      <b-textarea v-model="row.ku_saraf_r_patologis" class="form-control" rows="2" @change="updateRow(row)"></b-textarea>
                                    </div>

                                    <div class="row g-1">

                                      <div class="col-md-6">
                                        <label>Babinski</label>
                                        <v-select placeholder="Status" v-model="row.ku_saraf_babinski" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)"></v-select>
                                      </div>

                                      <div class="col-md-6">
                                        <label>Lasegue</label>
                                        <v-select placeholder="Status" v-model="row.ku_saraf_lasegue" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)"></v-select>
                                      </div>

                                      <div class="col-md-6">
                                        <label>Bregard</label>
                                        <v-select placeholder="Status" v-model="row.ku_saraf_bregard" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)"></v-select>
                                      </div>

                                      <div class="col-md-6">
                                        <label>Sicard</label>
                                        <v-select placeholder="Status" v-model="row.ku_saraf_sicard" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)"></v-select>
                                      </div>

                                      <div class="col-md-6">
                                        <label>Kaku Duduk</label>
                                        <v-select placeholder="Status" v-model="row.ku_saraf_kaku_duduk" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)"></v-select>
                                      </div>

                                      <div class="col-md-6">
                                        <label>Kernig Sign</label>
                                        <v-select placeholder="Status" v-model="row.ku_saraf_kernig_sign" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)"></v-select>
                                      </div>

                                      <div class="col-md-6">
                                        <label>Brudzinski 1</label>
                                        <v-select placeholder="Status" v-model="row.ku_saraf_brudzinski" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)"></v-select>
                                      </div>

                                      <div class="col-md-6">
                                        <label>Brudzinski 2</label>
                                        <v-select placeholder="Status" v-model="row.ku_saraf_brudzinski2" :options="Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)"></v-select>
                                      </div>

                                    </div>

                                  </div>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="card border shadow-0">
                  <div class="bg-light card-header py-2">
                    <div class="d-flex align-items-center justify-content-between">
                      <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
                    </div>
                  </div>

                  <div class="card-body">
                    <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa" v-model="inputICD10" @search="filterICD10($event,0)" @input="selectICD10(inputICD10)" :options="mICD10" label="text" :clearable="true" :reduce="v=>v.value">
                      <template slot="selected-option">
                        <span v-b-tooltip.hover.right :title="inputICD10Name">
                          {{inputICD10Name||"-"}}
                        </span>
                      </template>

                      <template slot="option" slot-scope="option">
                        <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                      </template>
                      <template #list-footer v-if="(mICD10||[]).length >= 1">
                        <li class="list-paging">
                          <div class="d-flex align-items-center justify-content-between">
                            <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                            <small>Halaman {{mICD10[0].currentPage}} dari
                              {{ceilData(mICD10[0].totalRows/5)}}</small>
                            <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                          </div>
                        </li>
                      </template>
                    </v-select>
                  </div>

                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th width="24">Primer</th>
                        <th colspan="2">Item ICD-10 dipilih</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, k) in row.data_diagnosa" :key="`icd10-${k}`">
                        <td>
                          <b-form-checkbox v-model="row.diagnosa_primer" :id="`opticd10-${k}`" :value="v" @change="changePrimary()" />
                        </td>
                        <td>
                          <template v-if="v.aranci10d_diagnosis">
                            <div class="result_tab">
                              <h4>Diagnosa Lainnya</h4>
                              <p>{{ v.aranci10d_diagnosis }}</p>
                            </div>
                          </template>

                          <template v-else>
                            <span>{{ v.mi10_name}} <span class="font-weight-semibold">[{{ v.mi10_code }}]</span></span>
                          </template>

                          <VValidate name="Diagnosa Primer" v-model="row.diagnosa_primer" rules="required" />
                        </td>
                        <td>
                          <a href="javascript:;" @click="removedataICD10(k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                            <i class="icon-trash-alt"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="!(row.data_diagnosa||[]).length">
                        <td colspan="99" class="text-center">Tidak Ada Data</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th colspan="3">Diagnosa Lainnya</th>
                      </tr>
                      <tr v-for="(v, k) in row.data_diagnosa_lainnya" :key="`icd10other-${k}`">
                        <td v-if="v.text">
                          <b-form-checkbox v-model="row.diagnosa_primer" :id="`opticd10other-${k}`" :value="v" @change="changePrimary()" />
                        </td>
                        <td :colspan="!v.text ? 2 : ''">
                          <b-form-textarea v-model="v.text" @change="updateRow(row)">
                          </b-form-textarea>
                        </td>
                        <td>
                          <a href="javascript:;" @click="removedataDiagnosaLainnya(k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                            <i class="icon-trash-alt"></i>
                          </a>

                          <br />

                          <a href="javascript:;" @click="addDataDiagnosaLainnya()" class="btn btn-sm btn-icon alpha-info text-info-800 border-info mt-2" v-if="(k+1) == (row.data_diagnosa_lainnya||[]).length">
                            <i class="icon-plus2"></i>
                          </a>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card border shadow-0">
                  <div class="bg-light card-header py-2">
                    <div class="d-flex align-items-center justify-content-between">
                      <h6 class="card-title mb-0 font-weight-semibold">Tindakan</h6>
                    </div>
                  </div>

                  <div class="card-body">
                    <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan" v-model="inputICD9" @search="filterICD9($event,0)" @input="selectICD9(inputICD9)" :options="mICD9" label="text" :clearable="true" :reduce="v=>v.value">

                      <template slot="selected-option">
                        <span v-b-tooltip.hover.right :title="inputICD9Name">
                          {{inputICD9Name||"-"}}
                        </span>
                      </template>

                      <template slot="option" slot-scope="option">
                        <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                      </template>
                      <template #list-footer v-if="(mICD9||[]).length >= 1">
                        <li class="list-paging">
                          <div class="d-flex align-items-center justify-content-between">
                            <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                            <small>Halaman {{mICD9[0].currentPage}} dari
                              {{ceilData(mICD9[0].totalRows/5)}}</small>
                            <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                          </div>
                        </li>
                      </template>
                    </v-select>
                  </div>
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Item ICD-9-CM dipilih</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, k) in row.data_tindakan" :key="`icd9-${k}`">
                        <td>
                          <b-form-checkbox v-model="row.diagnosa_icd9" :id="`icd9-${k}`" :value="v" @change="updateRow(row)" />
                        </td>
                        <td><span>{{ v.mi9_name }} <span class="font-weight-semibold">[{{ v.mi9_code }}]</span></span></td>
                        <td>
                          <a href="javascript:;" @click="openInformConsentDetailForm(v)" v-if="v.aranci9d_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-b-tooltip.hover title="Lihat Informed Consent">
                            <i class="icon-file-eye2"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="!(row.data_tindakan||[]).length">
                        <td colspan="99" class="text-center">Tidak Ada Data</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th colspan="3">Tindakan Lainnya</th>
                      </tr>
                      <tr v-for="(v, k) in row.data_tindakan_lainnya" :key="`icd9other-${k}`">
                        <td v-if="v.text">
                          <b-form-checkbox v-model="row.diagnosa_icd9" :id="`opticd9other-${k}`" :value="v" @change="updateRow(row)" />
                        </td>
                        <td :colspan="!v.text ? 2 : ''">
                          <b-form-textarea v-model="v.text" @change="updateRow(row)">
                          </b-form-textarea>
                        </td>
                        <td>
                          <a href="javascript:;" @click="removedataTindakanLainnya(k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                            <i class="icon-trash-alt"></i>
                          </a>

                          <br />

                          <a href="javascript:;" @click="addDataTindakanLainnya()" class="btn btn-sm btn-icon alpha-info text-info-800 border-info mt-2" v-if="(k+1) == (row.data_tindakan_lainnya||[]).length">
                            <i class="icon-plus2"></i>
                          </a>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table table-sm table-bordered">
                        <thead>
                          <tr>
                            <td></td>
                            <th>Hasil Pemeriksaan</th>
                            <th>Hasil</th>
                            <th>Keterangan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(v,k) in tindakan.lab">
                            <tr v-for="(dtHasil, keyHasil) in v.arantl_hasil" :key="`lkh-${k}-${keyHasil}`" :id="k">
                              <td :rowspan="v.arantl_hasil.length == 1 ? 1 : v.arantl_hasil.length" v-if="keyHasil == 0">
                                <label>
                                  Laboratorium

                                  <ul class="pl-3">
                                    <li v-for="(dtTindakan, keyTindakan) in v.arantl_tindakan.split(', ')" :key="`atin-${keyTindakan}`">
                                      {{ dtTindakan }}
                                    </li>
                                  </ul>
                                </label>
                              </td>
                              <td>
                                {{ dtHasil.value }}
                              </td>
                              <td>
                                <a :href="uploader(dtHasil.file)" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" target="_blank" v-if="dtHasil.file">
                                  <i class="icon-file-eye2"></i>
                                </a>
                              </td>
                              <td>
                                <b-form-textarea placeholder="Keterangan" @change="updatePPNote('pp_lab', `lab_${k}${keyHasil}`, $event); updatePPNote('pp_lab_file', `labf_${k}${keyHasil}`, dtHasil.file);" lazy v-model="row.pp_lab[`lab_${k}${keyHasil}`]" />
                              </td>
                            </tr>
                          </template>

                          <tr v-if="!(tindakan.lab||[]).length">
                            <td>
                              <label>
                                Laboratorium
                              </label>
                            </td>
                            <td>
                              <span>Tidak ada pemeriksaan</span>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>

                          <!-- _ END Laboratorium -->

                          <template v-for="(v,k) in tindakan.radiologi">
                            <tr v-for="(dtHasil, keyHasil) in v.arantr_hasil" :key="`rkh-${k}-${keyHasil}`" :id="k">
                              <td :rowspan="v.arantr_hasil.length == 1 ? 1 : v.arantr_hasil.length" v-if="keyHasil == 0">
                                <label>
                                  Radiologi

                                  <ul class="pl-3">
                                    <li v-for="(dtTindakan, keyTindakan) in v.arantr_tindakan.split(', ')" :key="`ratin-${keyTindakan}`">
                                      {{ dtTindakan }}
                                    </li>
                                  </ul>
                                </label>
                              </td>
                              <td>
                                {{ dtHasil.value }}
                              </td>
                              <td>
                                <a :href="uploader(dtHasil.file)" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" target="_blank" v-if="dtHasil.file">
                                  <i class="icon-file-eye2"></i>
                                </a>
                              </td>
                              <td>
                                <b-form-textarea placeholder="Keterangan" @change="updatePPNote('pp_radiologi', `radiologi_${k}${keyHasil}`, $event); updatePPNote('pp_radiologi_file', `radiologif_${k}${keyHasil}`, dtHasil.file);" lazy v-model="row.pp_radiologi[`radiologi_${k}${keyHasil}`]" />
                              </td>
                            </tr>
                          </template>

                          <tr v-if="!(tindakan.radiologi||[]).length">
                            <td>
                              <label>
                                Radiologi
                              </label>
                            </td>
                            <td>
                              <span>Tidak ada pemeriksaan</span>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>

                          <!-- _ END Radiologi -->

                          <tr v-for="(v, k) in tindakan.fisio" :key="`tf-${k}`">
                            <td>
                              <label>
                                Fisioterapi
                              </label>

                              <ul class="pl-3">
                                <li v-for="(dtTindakan, keyTindakan) in [...(v.arantf_elektroterapi||[]), ...(v.arantf_mekanoterapi||[]), ...(v.arantf_latihan||[])]" :key="`fatin-${keyTindakan}`">
                                  <span v-if="dtTindakan == 'LE'">
                                    {{v.arantf_elektroterapi_text||"-"}}</span>
                                  <span v-else-if="dtTindakan == 'LM'">
                                    {{v.arantf_mekanoterapi_text||"-"}}</span>
                                  <span v-else-if="dtTindakan == 'LL'">
                                    {{v.arantf_latihan_text||"-"}}</span>
                                  <span v-else>{{ dtTindakan }}</span>
                                </li>
                              </ul>
                            </td>
                            <td>
                              <span>Tidak ada pemeriksaan</span>
                            </td>
                            <td></td>
                            <td>
                              <b-form-textarea placeholder="Keterangan" @change="updatePPNote('pp_fisioterapi', k, $event)" lazy v-model="row.pp_fisioterapi[k]" />
                            </td>
                          </tr>

                          <tr v-if="!(tindakan.fisio||[]).length">
                            <td>
                              <label>
                                Fisioterapi
                              </label>
                            </td>
                            <td>
                              <span>Tidak ada pemeriksaan</span>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                          </tr>

                          <!-- _ END Fisioterapi -->
                          <tr>
                            <td>
                              <label>
                                Lain-lain
                              </label>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                              <b-form-textarea placeholder="Keterangan" @change="updateRow(row)" v-model="row.pp_other" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Konsultasi/ Pengobatan</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <b-form-textarea rows="4" placeholder="Konsultasi/ Pengobatan" v-model="row.consultation" @change="updateRow(row)"></b-form-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Keadaan Keluar</h6>
                  </div>
                  <table class="table table-bordered table-sm">
                    <tr v-for="(v,k) in keadaanKeluar" :key="`kk-${k}`">
                      <td v-for="(dtKeadaanKeluar, keyKeadaanKeluar) in v" :key="`dkk-${keyKeadaanKeluar}`">
                        <b-form-radio v-model="row.keadaan_keluar" :value="dtKeadaanKeluar.label" @change="updateRow(row)">{{ dtKeadaanKeluar.label }}</b-form-radio>
                      </td>
                    </tr>
                  </table>

                  <VValidate name="Keadaan Keluar" rules="required" class="mt-2" :value="(row.keadaan_keluar||[]).length ? true : null" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Cara Keluar</h6>
                  </div>
                  <table class="table table-bordered table-sm">
                    <tr v-for="(v,k) in caraKeluar" :key="`ck-${k}`">
                      <td v-for="(dtCaraKeluar, keyCaraKeluar) in v" :key="`dck-${keyCaraKeluar}`">
                        <b-form-radio v-model="row.cara_keluar" :value="dtCaraKeluar.label" @change="updateRow(row)">{{ dtCaraKeluar.label }}</b-form-radio>
                      </td>
                    </tr>
                  </table>

                  <VValidate name="Cara Keluar" rules="required" class="mt-2" :value="(row.cara_keluar||[]).length ? true : null" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Tindak Lanjut</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="form-group">
                      <b-form-group>
                        <b-form-radio-group v-model="row.tindak_lanjut" :options="mrTindakLanjut" value-field="value" text-field="label" @change="updateRow(row)" class="radio-inline">
                        </b-form-radio-group>
                      </b-form-group>

                      <VValidate name="Tindak Lanjut" rules="required" :value="(row.tindak_lanjut||[]).length ? true : null" />
                    </div>
                    <div class="resTl" id="tlPoliklinik" v-if="row.tindak_lanjut == 'POLIKLINIK'"></div>
                    <div class="resTl" id="tlRsLain" v-if="row.tindak_lanjut == 'RUMAH_SAKIT_LAIN'">
                      <label for="tlRsLainInput">Nama Rumah Sakit</label>
                      <b-form-input v-model="row.hospital_name" @change="updateRow(row)" />

                      <VValidate name="Nama Rumah Sakit" v-model="row.hospital_name" rules="required" />
                    </div>
                    <div class="resTl" id="tlPuskesmas" v-if="row.tindak_lanjut == 'PUSKESMAS'">
                      <label for="tlPuskesmasInput">Nama Puskesmas</label>
                      <b-form-input v-model="row.puskesmas_name" @change="updateRow(row)" />

                      <VValidate name="Nama Puskesmas" v-model="row.puskesmas_name" rules="required" />
                    </div>
                    <div class="resTl" id="tlDrLain" v-if="row.tindak_lanjut == 'DOKTER_LAIN'">
                      <label for="tlDrLainInput">Nama Dokter</label>
                      <v-select placeholder=" -- Pilih Dokter -- " @input="updateRow(row)" v-model="row.dokter" :options="mrDokter" :clearable="true" label="text" :reduce="v=>v.value"></v-select>

                      <VValidate name="Nama Dokter" v-model="row.dokter" rules="required" />
                    </div>
                    <div class="resTl" id="tlLainnya" v-if="row.tindak_lanjut == 'LAINNYA'">
                      <label for="tlLainnyaInput">Lainnya</label>
                      <b-form-textarea rows="2" v-model="row.other_note" @change="updateRow(row)"></b-form-textarea>

                      <VValidate name="Catatan" v-model="row.other_note" rules="required" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Anjuran/Edukasi</h6>
                  </div>
                  <div class="card-body p-3">
                    <div>
                      <div>
                        <label for="rmAnjuran">Anjuran/Edukasi <strong class="text-danger">*</strong></label>
                        <b-form-textarea rows="4" v-model="row.reccomendation" @change="updateRow(row)"></b-form-textarea>

                        <VValidate name="Anjuran/Edukasi" rules="required" v-model="row.reccomendation" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-12">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pengobatan yang Diberikan</h6>
                  </div>
                  <table class="table table-striped table-sm table-hover table-bordered">
                    <tbody>
                      <tr v-if="(obatJadi||[]).length">
                        <td width="25%">
                          Obat Jadi
                        </td>
                        <td>
                          <ul>
                            <li v-for="(v,k) in (obatJadi||[])" :key="k"><span>{{v.arantfarr_nama||"-"}}</span></li>
                          </ul>
                        </td>
                      </tr>
                      <tr v-if="(obatRacikan||[]).length">
                        <td>
                          Obat Racikan
                        </td>
                        <td>
                          <ul>
                            <li v-for="(v,k) in (obatRacikan||[])" :key="k"><span>{{v.arantfarr_nama}}</span> : {{v.racikan}}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr v-if="!(obatJadi||[]).length && !(obatJadi||[]).length">
                        <td colspan="99">Tidak ada Obat Diberikan</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-12">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pengobatan yang Dilanjutkan di Rumah</h6>
                  </div>
                  <table class="table table-striped table-sm table-hover table-bordered">
                    <thead>
                      <tr>
                        <th width="48" v-if="!row.disabledResep">Aksi</th>
                        <th width="160">Jenis</th>
                        <th width="320">Nama</th>
                        <th width="150">Jumlah</th>
                        <th width="210">Frekuensi</th>
                        <th>Keterangan</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v,k) in resepDokter" :key="'resepDokter'+k">
                        <td v-if="!row.disabledResep">
                          <a href="javascript:;" @click="removeObat(v,k)" class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i class="icon-trash"></i></a>
                        </td>
                        <td>
                          <v-select :disabled="row.disabledResep" placeholder="Pilih Item" v-model="v.aranresmr_jenis" :options="Config.mr.configResepRanap" label="text" @input="changeJenis($event,k)" :reduce="v=>v.value">
                          </v-select>
                          <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi" v-model="v.aranresmr_jenis" :rules="{required : 1}" />
                        </td>
                        <td>
                          <div class="d-flex">
                            <template v-if="v['aranresmr_jenis'] == 1">
                              <v-select :disabled="row.disabledResep" style="flex: 1;" class="med-selection select-paging" placeholder="Pilih Item" :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'" @open="countLen" @search="filterObat($event,k);countLen();" v-model="v.aranresmr_item_id" :options="mObatPreferensi" label="text" @input="selectJumlahSatuan($event,k)" :clearable="true" :reduce="v=>v.value" :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                                <template slot="selected-option">
                                  <span :class="isAlergiLabel(v.aranresmr_nama)?'text-warning':''">{{ v.aranresmr_nama }}</span>
                                </template>
                                <template slot="option" slot-scope="option">
                                  <span :class="isAlergiLabel(option.text)?'text-warning':''" v-if="option.preferensi"><b>{{ option.text }}</b></span>
                                  <span :class="isAlergiLabel(option.text)?'text-warning':''" v-else>{{ option.text }}</span>
                                </template>
                                <template #list-footer>
                                  <li class="list-paging">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <b-button @click="changePageObat(v.currentPage||1,k,'min','resepDokter')" size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                        <i class="icon-arrow-left22 text-white"></i></b-button>
                                      <small>Halaman {{ v.currentPage||1 }} dari {{ ceilData(v.totalRows/10) }}</small>
                                      <b-button @click="changePageObat(v.currentPage||1,k,'plus','resepDokter')" size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                        <i class="icon-arrow-right22 text-white"></i></b-button>
                                    </div>
                                  </li>
                                </template>
                              </v-select>
                              <br />
                            </template>

                            <template v-else-if="v['aranresmr_jenis'] == 2">
                              <v-select :disabled="row.disabledResep" style="flex: 1;" class="med-selection select-paging select-custom-height" placeholder="Pilih Item" v-model="v.aranresmr_item_id" :options="mAlatKesehatan" label="text" @input="selectJumlahSatuanAlkes($event,k)" :clearable="true" :reduce="v=>v.value">
                                <template slot="selected-option" slot-scope="option">
                                  <span>{{ option.text }}</span>
                                </template>
                                <template slot="option" slot-scope="option">
                                  <span>{{ option.text }}</span>
                                </template>
                              </v-select>
                            </template>

                            <template v-else-if="v['aranresmr_jenis'] == 3">
                              <v-select :disabled="row.disabledResep" style="flex: 1;" class="med-selection select-paging select-custom-height" placeholder="Pilih Item" v-model="v.aranresmr_item_id" :options="mRacikan" label="text" @input="selectJumlahSatuanRacikan($event,k)" :clearable="true" :reduce="v=>v.value">
                                <template slot="selected-option" slot-scope="option">
                                  <span>{{ option.text }}</span>
                                </template>
                                <template slot="option" slot-scope="option">
                                  <span>{{ option.text }}</span>
                                </template>
                              </v-select>
                            </template>

                            <template v-else-if="v['aranresmr_jenis'] == 5">
                              <v-select :disabled="row.disabledResep" style="flex: 1;" class="med-selection select-paging select-custom-height" placeholder="Pilih Item" v-model="v.aranresmr_item_id" :options="mAlatInfus" label="text" @input="selectJumlahSatuanInfus($event,k)" :clearable="true" :reduce="v=>v.value">
                                <template slot="selected-option" slot-scope="option">
                                  <span>{{ option.text }}</span>
                                </template>
                                <template slot="option" slot-scope="option">
                                  <span>{{ option.text }}</span>
                                </template>
                              </v-select>
                            </template>
 
                            <a href="javascript:;" v-if="(v['aranresmr_jenis'] !== 3) && !row.disabledResep " @click="v['aranresmr_item_id'] = 99999; v['aranresmr_nama'] = 'Lainnya'" v-b-tooltip.hover="'Pilih Lainnya'" class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i class="icon-plus-circle2"></i></a>
                          </div>
                          <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi" v-model="v['aranresmr_item_id']" :rules="{required : 1}" />

                          <div v-if="v['aranresmr_item_id'] == 99999">
                            <b-form-input :disabled="row.disabledResep" type="text" v-model="v['aranresmr_item_lainnya']" class="form-control" id="itemCount" />
                            <VValidate :name="'Item Lainnya '+(k+1)" message="Nama Item Lainnya Harus Diisi" v-model="v['aranresmr_item_lainnya']" :rules="{required : 1}" />
                          </div>

                          <a v-if="v.aranresmr_jenis == 3 && v['aranresmr_item_id']" href="javascript:;" @click="toRacikan(v)" data-popup="tooltip" title="" data-toggle="modal" data-target="#modalRacikanDetail" class="font-weight-semibold border-bottom" data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>

                          <small class="text-warning" v-if="isAlergiLabel(v.aranresmr_nama) ">Obat ini termasuk dalam
                            kategori alergi</small>
                        </td>
                        <td>
                          <div class="input-group">
                            <b-form-input type="text" :disabled="row.disabledResep" v-model="v['aranresmr_jumlah']" :formatter="Formatter.number" class="form-control" id="itemCount" />
                            <div class="input-group-append">
                              <span class="input-group-text">{{v['aranresmr_satuan']}}</span>
                            </div>
                          </div>

                          <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi" v-model="v['aranresmr_jumlah']" :rules="{required : 1}" />
                        </td>
                        <td>
                          <span class="d-flex">
                            <v-select :disabled="row.disabledResep" style="flex: 1;" class="med-selection" placeholder="Pilih Frekuensi" v-model="v.aranresmr_frekuensi" :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                            </v-select>
                            <a href="javascript:;" v-if="!row.disabledResep" @click="v['aranresmr_frekuensi'] = 99999" v-b-tooltip.hover="'Pilih Lainnya'" class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i class="icon-plus-circle2"></i></a>
                          </span>
                          <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi" v-model="v['aranresmr_frekuensi']" v-if="v.aranresmr_jenis != 2" :rules="{required : 1}" />

                          <div v-if="v['aranresmr_frekuensi'] == 99999">
                            <b-form-input :disabled="row.disabledResep" type="text" v-model="v['aranresmr_frekuensi_lainnya']" class="form-control" id="itemCount" />
                            <VValidate :name="'Frekuensi Lainnya '+(k+1)" message="Frekuensi Lainnya Harus Diisi" v-if="v.aranresmr_jenis != 2" v-model="v['aranresmr_frekuensi_lainnya']" :rules="{required : 1}" />
                          </div>
                        </td>
                        <td>
                          <b-form-textarea :disabled="row.disabledResep" v-model="v.aranresmr_keterangan" name="itemNotes" id="itemNotes" rows="2" placeholder="cth. obat dihabiskan" class="form-control"></b-form-textarea>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!row.disabledResep">
                      <tr>
                        <td class="text-center" colspan="99">
                          <a href="javascript:;" @click="openObat()" class="btn alpha-blue">
                            <i class="icon-plus2 mr-1 align-middle"></i>
                            <span class="align-middle">Tambah Resep</span>
                          </a>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div class="col-12">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Jadwal Kontrol Berikutnya </h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div>
                          <table class="table table-bordered table-striped table-hover table-sm patient-table">
                            <thead>
                              <tr>
                                <th width="250">Nama dokter</th>
                                <th width="250">Tanggal </th>
                                <th width="250">Tempat </th>
                                <th width="10">Aksi </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v, k) in row.jadwal_kontrol" :key="`jk-${k}`">
                                <td>
                                  <v-select v-model="v.dokter" :options="mrDokter" :reduce="v => v.value" label="text" @input="updateRow(row)" />
                                </td>
                                <td>
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                    </div>
                                    <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="v.date" @input="updateRow(row)">
                                    </datepicker>
                                  </div>
                                </td>
                                <td>
                                  <v-select v-model="v.place" :options="mrPlace" :reduce="v => v.value" @input="updateRow(row)" />
                                </td>

                                <td>
                                  <a href="javascript:;" @click="deleteJadwalKontrol(v, k)" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus Jadwal">
                                    <i class="icon-trash"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colspan="3" class="text-center">
                                  <a href="javascript:;" @click="addData('jadwal_kontrol')" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-end">
              <button type="button" class="btn" @click="$router.back()">Kembali</button>
              <button class="btn btn-labeled btn-labeled-left btn-primary">
                <b><i class="icon-paperplane"></i></b>
                <span>Kirimkan</span>
              </button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>

    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
      <table class="table table-bordered table-sm table-input">
        <thead>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Nama Tindakan / ICD-9-CM</h4>
                <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
              </div>
            </td>
          </tr>
          <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
              {{rowIC.aranic_diagnosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
              {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
              {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
              {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
              {{rowIC.aranic_tata_cara||"-"}}
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
              {{rowIC.aranic_tujuan||"-"}}
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
              {{rowIC.aranic_risiko||"-"}}
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
              {{rowIC.aranic_kompilasi||"-"}}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
              {{rowIC.aranic_prognosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
              {{rowIC.aranic_alternatif||"-"}}
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
              {{rowIC.aranic_lainnya||"-"}}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td width="50%" colspan="2">
              <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
              </div>
            </td>
            <td width="50%">
              <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </b-modal>
  </div>
</div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import Gen from '@/libs/Gen'
import Formatter from '@/libs/Formatter'
import MonitoringOptions from '@/libs/MonitoringOptions'
import GlobalVue from '../../../libs/Global.vue'

import Datepicker from 'vuejs-datepicker'

import {
  stringSimilarity
} from "string-similarity-js"
import $ from 'jquery'

const _ = global._

export default {
  extends: GlobalVue,
  components: {
    PatientInfo,
    Datepicker
  },

  data() {
    return {
      loading: {
        patientInfo: false
      },

      obatLen: 0,
      patientData: {},
      mrValidation: {},
      row: {
        alergi: [],
      },
      rowReg: {},
      rowRacikan: {},

      resepDokter: [],

      mrJenisAlergi: [],
      mrGCS: {
        responseMata: [],
        responseVerbal: [],
      },
      mObatPreferensi: [],
      mDosis: [],
      mAlatKesehatan: [],
      mAlatInfus: [],
      mrKeadaanKeluar: [],
      mrTindakLanjut: [],
      mrDokter: [],
      mrPlace: [],
      mRacikan: [],
      mrCaraKeluar: [],
      dataCPPT: {},

      icd9: [],
      icd10: [],
      tindakan: {},
      rowIC: {},
      openModalICDetail: false,
      showKeadaanUmum: false,

      mICD9: [],
      inputICD9Name: null,
      searchICD9Name: null,
      inputICD9: null,

      mICD10: [],
      inputICD10Name: null,
      searchICD10Name: null,
      inputICD10: null,

      obatRacikan: [],
      obatJadi: [],

      Formatter,
      MonitoringOptions,
    }
  },

  mounted() {
    this.getPatientInfo()
    this.getFormData()
  },

  methods: {
    addDataDiagnosaLainnya() {
      this.row.data_diagnosa_lainnya.push({
        text: null
      })

      this.updateRow(this.row)
    },
    addDataTindakanLainnya() {
      this.row.data_tindakan_lainnya.push({
        text: null
      })

      this.updateRow(this.row)
    },
    removedataICD9(k) {
      this.row.data_tindakan.splice(k, 1)
      this.updateRow(this.row)
    },
    removedataTindakanLainnya(k) {
      this.row.data_tindakan_lainnya.splice(k, 1)
      this.updateRow(this.row)
    },
    removedataICD10(k) {
      this.row.data_diagnosa.splice(k, 1)
      this.updateRow(this.row)
    },
    removedataDiagnosaLainnya(k) {
      this.row.data_diagnosa_lainnya.splice(k, 1)
      this.updateRow(this.row)
    },
    selectICD9(e) {
      let index = this.mICD9.findIndex(x => x.value == e)
      if (index !== -1) {
        this.inputICD9Name = this.mICD9[index]['text']
        this.searchICD9Name = null
      } else {
        this.inputICD9Name = null
      }

      this.row.data_tindakan.push({
        ...this.mICD9[index],
        mi9_name: this.mICD9[index].description,
        mi9_code: this.mICD9[index].code,
      })

      this.updateRow(this.row)
    },
    filterICD9: _.debounce(function (e, k) {
      let id = []
      for (let i = 0; i < (this.row.data_tindakan || []).length; i++) {
        if (this.row.data_tindakan[i]['value']) {
          id.push(this.row.data_tindakan[i]['value'])
        }
      }

      this.searchICD9Name = e

      Gen.apiRest('/do/' + "RanapCPPTDokter", {
        data: {
          type: 'select-icd-9',
          search: e,
          id: id
        }
      }, 'POST').then(res => {
        this.mICD9 = res.data.data
      })
    }, 10),

    changePageICD9(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }
      let data = {
        type: 'select-paging-icd-9',
        page: page,
        search: this.searchICD9Name
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.mICD9 = res.data.data
        this.getmICD9(this.dataICD9, k)
      })
    },

    getmICD9(diagnosa, k) {
      const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.api9_icd_9
      })
      return _.filter(this.mICD9, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },

    selectICD10(e) {
      let index = this.mICD10.findIndex(x => x.value == e)
      if (index !== -1) {
        this.inputICD10Name = this.mICD10[index]['text']
        this.searchICD10Name = null
      } else {
        this.inputICD10Name = null
      }

      this.row.data_diagnosa.push({
        ...this.mICD10[index],
        mi10_name: this.mICD10[index].description,
        mi10_code: this.mICD10[index].code,
      })

      this.updateRow(this.row)
    },

    filterICD10: _.debounce(function (e, k) {
      let id = []
      for (let i = 0; i < (this.row.data_diagnosa || []).length; i++) {
        if (this.row.data_diagnosa[i]['value']) {
          id.push(this.row.data_diagnosa[i]['value'])
        }
      }

      this.searchICD10Name = e

      Gen.apiRest('/do/' + "RanapCPPTDokter", {
        data: {
          type: 'select-icd-10',
          search: e,
          id: id
        }
      }, 'POST').then(res => {
        this.mICD10 = res.data.data
      })
    }, 10),

    changePageICD10(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }

      let data = {
        type: 'select-paging-icd-10',
        page: page,
        search: this.searchICD10Name
      }
      Gen.apiRest(
        "/do/" + "RanapCPPTDokter", {
          data: data
        },
        "POST"
      ).then(res => {
        this.mICD10 = res.data.data
        this.getmICD10(this.dataICD10, k)
      })
    },

    deleteJadwalKontrol(data, key) {
      console.log(data)
      if (data.id) {
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: {
              id: data.id,
              type: 'delete-jadwal-kontrol'
            }
          },
          "POST"
        ).then(() => {
          this.row.jadwal_kontrol.splice(key, 1)
        })
      } else {
        this.row.jadwal_kontrol.splice(key, 1)
      }
    },
    addData(property) {
      const obj = property == 'jadwal_kontrol' ? {
        dokter: (this.user == 5 || this.user == 7 || this.user == 24) ?   +this.user.id : null,
        place: 'RSH'
      } : {}
      if (!this.row[property]) {
        this.$set(this.row, property, [obj])
      } else {
        this.row[property].push(obj)
      }
    },
    openInformConsentDetailForm(v) {
      Gen.apiRest('/do/' + this.modulePage, {
        data: {
          type: 'get-data-ic',
          id: v.aranci9d_informed_consent,
        }
      }, 'POST').then(res => {
        this.rowIC = res.data.row
        this.rowIC.mi9_name = this.row.arancpd_tindakan_text
        this.rowIC.mi9_code = ''
        this.rowIC.id_assesment = this.row.arancpd_id
        this.openModalICDetail = true
      })
    },
    autoSaveResepObat: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-resep-obat',
        data: data
      }
      if (!this.row.isEdit) {
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: dataPost
          },
          "POST"
        )
      }
    }, 1000),
    updatePPNote(property, key, value) {
      if (!this.row[property][key]) {
        if (Object.keys(this.row[property]).length) {
          this.row[property] = {
            ...this.row[property],
            [key]: value
          }
        } else {
          this.row[property] = {
            [key]: value
          }
        }
      } else {
        this.$set(this.row[property], key, value)
      }

      setTimeout(() => {
        this.updateRow(this.row)
      }, 700)
    },
    changePrimary() {
      setTimeout(() => {
        this.$set(this.row, 'diagnosa_sekunder', this.icd10.filter(icd => {
          return this.row.diagnosa_primer.map(dp => dp.aranci10d_id||dp.value).indexOf((icd.aranci10d_id||icd.value)) < 0
        }))

        this.updateRow(this.row)
      }, 1000)
    },
    isAlergiLabel(nama) {
      let data = []
      for (let i = 0; i < (this.row.alergi || []).length; i++) {
        if (this.row.alergi[i]['jenis'] == 'Obat') {
          data.push(this.row.alergi[i]['name'])
        }
      }
      let isWarning = 0
      for (let i = 0; i < (data || []).length; i++) {
        let result = stringSimilarity(nama || "", data[i])
        if (result > 0.3) isWarning += 1
      }
      return isWarning
    },
    countLen() {
      setTimeout(() => {
        this.obatLen = $('.sp-col ul li').length
      }, 100)
    },
    doSubmit() {
      if((this.row.cara_keluar == "Atas Persetujuan" || this.row.cara_keluar == "Berobat Jalan") && !(this.row.jadwal_kontrol||[]).length){
        return this.$swal({
          icon: 'error',
          title: 'Jadwal Kontrol Wajib Diisi'
        })
      }
      
      this.$refs['VForm'].validate().then(status => {
        if (!status) return false

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if (result.value) {
            this.loadingOverlay = true
            Gen.apiRest(
              "/do/" + this.modulePage, {
                data: {
                  ...this.row,
                  id_registrasi: this.$route.params.pageSlug,
                  id_cppt: this.dataCPPT.arancp_id,
                  data_obat: this.resepDokter,
                  type: 'submit'
                }
              },
              "POST"
            ).then(res => {
              this.loadingOverlay = false

              this.$socket.emit('refresh_data_bed', {})
              this.getNotificationSelesai()

              this.$swal({
                title: "Berhasil kirim resume medis",
                icon: 'success',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(result => {
                if (result.value) {
                  this.$router.back()
                }
              })
            }).catch(() => {
              setTimeout(() => {
                this.loadingOverlay = false

                this.$swal({
                  title: "Gagal kirim resume medis",
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }, 700)
            })
          }
        })

      })
    },
    updateRow: _.debounce(function (data) {
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
            id_registrasi: this.$route.params.pageSlug,
            type: this.$route.params.pageSlug ? 'update' : 'add'
          }
        },
        "POST"
      ).then(res => {
        this.$set(this.row, 'jadwal_kontrol', res.data.data.jadwal_kontrol)
      })
    }, 300),

    removeData(property, keyData) {
      this.row[property].splice(keyData, 1)
    },

    getFormData() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: 'get-form',
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          if (["row"].indexOf(k) >= 0) {
            if (Array.isArray(v['pp_lab'])) v['pp_lab'] = {}
            if (Array.isArray(v['pp_radiologi'])) v['pp_radiologi'] = {}
            if (Array.isArray(v['pp_lab_file'])) v['pp_lab_file'] = {}
            if (Array.isArray(v['pp_radiologi_file'])) v['pp_radiologi_file'] = {}

            this.$set(this, k, v)
          } else {
            this.$set(this, k, v)
          }
        })
      })
    },
    removeObat(v, k) {
      let data = {
        type: 'remove-resep-dokter',
        id: v.aranresmr_id
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter.splice(k, 1)
      })
    },
    changePageObat(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }

      let data = {
        type: 'select-paging-obat',
        page: page,
        idDokter: this.rowReg.aranr_dokter_id,
        search: this[col][k]['search']
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.mObatPreferensi = res.data.data
      })
    },

    openObat(v = null) {
      let data = {
        aranresmr_aranr_id: this.rowReg.aranr_id,
        aranresmr_arantfar_id: this.$route.params.resepNo,
        aranresmr_jenis: 1,
        aranresmr_item_id: null,
        aranresmr_nama: null,
        aranresmr_jumlah: null,
        aranresmr_satuan: null,
        aranresmr_frekuensi: null,
        aranresmr_frekuensi_lainnya: null,
        aranresmr_keterangan: null,
        aranresmr_code: null,
        aranresmr_is_alergi: null,
        aranresmr_signa1: null,
        aranresmr_signa2: null,
        id: null
      }

      data.type = 'add-resep-dokter'
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.resepDokter.push(resp.row)
      })

    },
    resetObat() {
      this.loadingOverlay = true
      let data = {
        id: this.row.aranresmr_id,
        type: 'get-resep-dokter'
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.resepDokter = resp.data
        this.loadingOverlay = false
      })
    },
    addObat() {
      this.rowRacikan.aranresmr_racikan.push({
        value: null,
        dosis: null,
        satuan_dosis: null,
        jumlah: null,
        satuan_jumlah: null,
      })
    },
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)
        this.$set(this.loading, 'patientInfo', false)
      })
    },

    ceilData(value) {
      return Math.ceil(value)
    },
    getObat(val) {
      let index = this.mObat.findIndex(x => x.value == val)
      if (index !== -1) {
        return this.mObat[index]['text']
      } else {
        return null
      }
    },

    getMasterObat(data, k) {
      const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.value
      })
      return _.filter(this.mObat, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },
    selectObat(e, k) {
      let index = this.mObat.findIndex(x => x.value == e)
      if (index !== -1) {
        let data = this.mObat[index]
        this.rowRacikan.aranresmr_racikan[k]['dosis'] = data.dosis
        this.rowRacikan.aranresmr_racikan[k]['satuan_dosis'] = data.satuan_dosis
        this.rowRacikan.aranresmr_racikan[k]['satuan_jumlah'] = data.satuan
      }
    },
    changeJenis(e, k) {
      this.resepDokter[k].aranresmr_item_id = null
      this.resepDokter[k].aranresmr_frekuensi = null
      this.resepDokter[k].aranresmr_frekuensi_lainnya = null
    },
    filterObat: _.debounce(function (e, k) {
      if (e) {
        this.resepDokter[k]['search'] = e
        let id = []
        for (let i = 0; i < (this.resepDokter || []).length; i++) {
          if (this.resepDokter[i]['aranresmr_item_id'] && this.resepDokter[i]['aranresmr_jenis'] == 1) {
            id.push(this.resepDokter[i]['aranresmr_item_id'])
          }
        }
        Gen.apiRest('/do/' + 'RanapResumeMedis', {
          data: {
            type: 'select-obat',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.mObatPreferensi = res.data.data
          this.$set(this.resepDokter[k], 'currentPage', 1)
          this.$set(this.resepDokter[k], 'totalRows', res.data.totalRows)
        })
      }
    }, 10),

    selectJumlahSatuan(e, k) {
      let data = {
        id: e,
        type: 'get-by-obat'
      }
      Gen.apiRest(
        "/do/" + 'RanapResumeMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].aranresmr_satuan = res.data.satuan
        this.resepDokter[k].aranresmr_nama = res.data.nama
        this.resepDokter[k].aranresmr_racikan = null

        this.resepDokter[k].aranresmr_dosis = res.data.dosis
        this.resepDokter[k].aranresmr_satuan_dosis = res.data.satuan_dosis
        this.resepDokter[k].aranresmr_item_lainnya = null

        this.isAlergi(res.data.nama, k)
      })
    },
    isAlergi(nama, k) {
      let data = []
      for (let i = 0; i < (this.row.alergi || []).length; i++) {
        if (this.row.alergi[i]['jenis'] == 'Obat') {
          data.push(this.row.alergi[i]['name'])
        }
      }
      let isWarning = 0
      for (let i = 0; i < (data || []).length; i++) {
        let result = stringSimilarity(nama || "", data[i])
        if (result > 0.3) isWarning += 1
      }

      this.resepDokter[k]['aranresmr_is_alergi'] = isWarning ? "Y" : "N"
    },
    selectJumlahSatuanInfus(e, k){
      let data = {
        id: e,
        type: 'get-by-infus'
      }
      Gen.apiRest(
        "/do/" + 'RanapResumeMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].aranresmr_satuan = res.data.satuan
        this.resepDokter[k].aranresmr_nama = res.data.nama
        this.resepDokter[k].aranresmr_racikan = null

        this.resepDokter[k].aranresmr_dosis = null
        this.resepDokter[k].aranresmr_satuan_dosis = null
        this.resepDokter[k].aranresmr_frekuensi = null
        this.resepDokter[k].aranresmr_frekuensi_lainnya = null
        this.resepDokter[k].aranresmr_item_lainnya = null
      })
    },
    selectJumlahSatuanAlkes(e, k) {
      let data = {
        id: e,
        type: 'get-by-alkes'
      }
      Gen.apiRest(
        "/do/" + 'RanapResumeMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].aranresmr_satuan = res.data.satuan
        this.resepDokter[k].aranresmr_nama = res.data.nama
        this.resepDokter[k].aranresmr_racikan = null

        this.resepDokter[k].aranresmr_dosis = null
        this.resepDokter[k].aranresmr_satuan_dosis = null
        this.resepDokter[k].aranresmr_frekuensi = null
        this.resepDokter[k].aranresmr_frekuensi_lainnya = null
        this.resepDokter[k].aranresmr_item_lainnya = null
      })
    },
    selectJumlahSatuanRacikan(e, k) {
      let data = {
        id: e,
        type: 'get-by-racikan'
      }
      Gen.apiRest(
        "/do/" + 'RanapResumeMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].aranresmr_satuan = res.data.satuan
        this.resepDokter[k].aranresmr_nama = res.data.nama
        this.resepDokter[k].aranresmr_racikan = res.data.data

        this.resepDokter[k].aranresmr_dosis = null
        this.resepDokter[k].aranresmr_satuan_dosis = null
        this.resepDokter[k].aranresmr_frekuensi = null
        this.resepDokter[k].aranresmr_frekuensi_lainnya = null
        this.resepDokter[k].aranresmr_item_lainnya = null
      })
    },
    getNotificationSelesai(){
      let data = {
        type: "get-notif-pasien-selesai"
      }
      Gen.apiRest(
        "/do/" + 'Notification', {
          data: data
        },
        "POST"
      ).then(res => {
        // pasien pulang yah
        $("#pasien-pulang-notif").remove()
        if(res.data.notifPasienSelesai){
          $("#RanapPasienSelesai a").append(`<span title="Jumlah Pasien Pulang" id="pasien-pulang-notif" class="badge badge-info ml-auto">
          ${res.data.notifPasienSelesai}
          </span>`)
        }
      })
    },
  },

  computed: {
    modulePage() {
      return this.$route.name
    },

    keadaanKeluar() {
      return _.chunk(this.mrKeadaanKeluar, 2)
    },
    caraKeluar() {
      return _.chunk(this.mrCaraKeluar, 2)
    },
  },

  watch: {
    $route() {
      this.getPatientInfo()
      this.getFormData()
    },
    resepDokter: {
      handler(v) {
        this.autoSaveResepObat(v)
      },
      deep: true
    }
  }
}
</script>
